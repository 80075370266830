<template>
    <section v-if="jsonTop" class="page-storytelling">
        <div class="storytelling-top thefold">
            <FeaturedSlides :bgContentArray="jsonTop.backgrounds"></FeaturedSlides>
            <div class="scrim"></div>
            <div class="capper copy-capper" :class="['capper', (revealReady) ? 'opacity1' : 'opacity0']" >
                <div class="copy">
                    <router-link :to="jsonTop.cta">
                        <div class="rte no-max-width">
                            <h3 class="title" v-html="jsonTop.title"></h3>
                        </div>
                        <div class="rte b">
                            <div class="subtitle" v-html="jsonTop.subtitle"></div>
                        </div>
                    </router-link>
                </div>

            </div>
        </div>
        <div class="storytelling-detail scroll-check-trigger">
            <div class="capper">
                <div class="capper-padded-4x">
                    <div class="intro" v-if="jsonDetail?.intro">
                        <div data-aos-delay="300" data-aos="custom-fade-in" class="rte" >
                            <h1 data-aos-delay="300" data-aos="custom-fade-in" v-html="jsonDetail?.title"></h1>
                            <div class="txt" v-html="jsonDetail?.intro"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="storytelling-detail-grid">
            <div class="capper">


                    <div class="grid-wrapper">
                        <div class="grid" v-for="(item, index) in jsonDetail?.content" :key="`grid-${index}`">
                            <div class="title">
                                <div class="rte no-max-width">
                                    <h2 data-aos="custom-fade-in" v-html="item.title"></h2>
                                </div>
                            </div>
                            <div class="projects">
                                <div v-for="(item2, index2) in item.projects" :key="`grid-${index}-${index2}`" class="project"  >
                                    <a class="poster-a" :href="item2.url" @click.prevent="openFilmPopup(item2.slug)">
                                        <div data-aos="custom-fade-in" :alt="item2.title" :title="item2.title" class="poster " :class="(!item2.bgWithStyle) ? 'no-poster' : ''" >
                                            <div class="bgimg" :style="item2.bgWithStyle"></div>
                                            <div v-if="!item2.bgWithStyle" class="no-poster-logo" >
                                                <img loading="lazy" src="@/assets/images/global/film-no-poster.svg" />
                                            </div>
                                        </div>
                                        <div class="details">
                                            <div class="details2">
                                                DETAILS
                                            </div>
                                        </div>
                                    </a>

                                    <!--
                                    <div class="bottom-anchored">
                                        <div data-aos="custom-fade-in" v-if="item2.ctaLink" class="link">
                                            <a :href="item2.ctaLink" target="_blank">
                                                <div class="btn fullWidth">
                                                    <span>{{item2.ctaLabel}}</span>
                                                </div>
                                            </a>
                                        </div>
                                        <div data-aos="custom-fade-in" v-if="item2.cta2Link" class="link">
                                            <a :href="item2.cta2Link" target="_blank">
                                                <div class="btn fullWidth">
                                                    <span>{{item2.cta2Label}}</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    -->

                                    <div class="anchored-copy">
                                        <div class="top-anchored">
                                            <div class="rte no-margin">
                                                <h6 data-aos="custom-fade-in">{{ item2.title }}<sup v-if="item2.beyondInmaat" style="top:-5px">*</sup></h6>

                                                <!--
                                                <div data-aos="custom-fade-in" v-if="item2.genre" class="genre"><p>{{ item2.genre }}</p></div>
                                                <div data-aos="custom-fade-in" v-if="item2.principles" class="principle">
                                                    <div class="principle" v-for="(item, index) in item2.principles" :key="`item-${index}-${Math.random()}`" data-aos="custom-fade-in">
                                                        <div class="rte">
                                                            <p><b>{{item.position}}:</b> {{item.name}}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div data-aos="custom-fade-in" v-if="item2.quote.quote" class="quote-outer">
                                                    <div class="quote"  v-html='`<p>“${item2.quote.quote}”</p>`'></div>
                                                    <div v-if="item2.quote.attribute" class="attribute" ><small v-html='`-${item2.quote.attribute}`'></small></div>
                                                </div>

                                                <div data-aos="custom-fade-in" class="rte">
                                                    <div v-if="item2.moreInfo" :id="`moreInfo-${index}-${index2}`" class="moreInfo closed" >
                                                        <div class="collapsed">
                                                            <div class="closed" @click="showMoreInfo(index, index2)"><span class="txt">Synopsis</span> <span class="keyboard_arrow_down material-icons">keyboard_arrow_down</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                -->
                                            </div>
                                        </div>

                                        <div :id="`expanded-${index}-${index2}`" class="moreinfo-expanded closed">
                                            <div @click="hideMoreInfo()"  class="close-button small"></div>
                                            <div class="inner">
                                                <div class="rte" >
                                                    <div v-html="item2.moreInfo"></div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>



                                </div>
                            </div>
                            <div data-aos="custom-scale-from-center" class="generic-line full"></div>
                        </div>
                    </div>
            </div>
        </div>

        <div class="storytelling-detail-bottom">
            <div class="capper">
                <div class="capper-padded">
                    <div v-if="jsonDetail?.listContent" class="list-links">
                        <div class="title" >
                            <div class="rte">
                                <h2 data-aos="custom-fade-in" v-html="jsonDetail?.listContent.title"></h2>
                            </div>
                        </div>

                        <div class="items">

                            <div class="item" v-for="(item, index) in jsonDetail?.listContent?.projects" :key="`item-${index}-${Math.random()}`" data-aos="custom-fade-in">
                                <div class="rte">
                                    <h5 v-html="item.title"></h5>
                                    <div class="item-content" v-html="stripEmptyTags(item.content)"></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import FeaturedSlides from "@/components/blocks/FeaturedSlides"
import gsap, {Power3} from 'gsap'
import { SplitText} from 'gsap/SplitText'
import { mapState } from 'vuex'
gsap.registerPlugin(SplitText)
export default {
    name: "StorytellingPage",
    components: {
        FeaturedSlides
    },
    data () {
        return {
            window: window,
            document: document,
            jsonTop: null,
            jsonDetail: null,
            transition: false,
            inPanelTransition: false,
            revealReady: false,

            currentMoreInfo:false
        }

    },

    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}storytelling${window.config.apiext}`))
            .then((res) => {
                if (res.data.top?.backgrounds) {
                    for (let i=0;i<res.data.top?.backgrounds.length;i++) {
                        let vidObj = res.data.top?.backgrounds[i]
                        if (vidObj?.bgVideo) {
                            let video = document.createElement('video')
                            video.src = vidObj?.bgVideo;
                            video.load();
                        }
                    }
                }

                this.jsonTop = res.data.top


                this.loadStoriesBelow(res.data.detail)
                if (this.didInitialSiteTransition) {
                    if (!this.inPanelTransition) {
                        setTimeout(this.reveal,10)
                    }
                }
            }).catch((e) => {
                console.error('Error',slug,e)
        })

    },
    watch: {
        didInitialSiteTransition() {
            if (!this.inPanelTransition) {
                this.reveal()
            }
        },
    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition,
            popupOpen: state => state.popupOpen,
        }),
    },
    methods: {
        loadStoriesBelow(res) {
            setTimeout(() => {
                this.jsonDetail = res;
                console.log('this.$route', this.$route);
                let slug = this.$route.fullPath.split('/')[2] || '';
                if (slug) {
                    this.openFilmPopup(slug);
                }
            }, 250);
        },
        reveal () {
            this.inPanelTransition = true

            /*
            new SplitText('.page-storytelling .rte h1', {
                type: 'words, chars',
                charsClass: 'txt-split'
            });

             */
            new SplitText('.page-storytelling .rte .subtitle', {
                type: 'words, chars',
                charsClass: 'txt-split'
            });


            let tl = gsap.timeline({
                delay:0.75
            });

            tl.fromTo('.page-storytelling .rte .title',
                {
                    opacity: 0,
                },
                {
                    duration: 0.7,
                    opacity: 1,
                    ease: Power3.easeOut
                },
                0.75
            )
            tl.fromTo('.page-storytelling .txt-split',
                {
                    opacity: 0,
                },
                {
                    duration: 0.7,
                    opacity: 1,
                    ease: Power3.easeOut,
                    stagger: 0.01,
                },
                "-=0.5"
            )
            /*
            tl.fromTo('.page-storytelling .generic-line',
                {
                    width: 0,
                },
                {
                    duration: 0.7,
                    width: 100,//100px
                    ease: Power3.easeOut,
                },
                "-=0.5"
            )

            tl.fromTo('.page-storytelling .btn-holder',
                {
                    opacity: 0,
                },
                {
                    duration: 0.7,
                    opacity: 1,//100px
                    ease: Power3.easeOut,
                },
                "-=0.5"
            ),

             */

            this.revealReady = true


        },
        unreveal () {

        },

        closeAllMoreInfos() {

            let elements = this.document.querySelectorAll('.moreInfo')
            elements.forEach(element => {
                element.classList.remove('show')
                element.classList.add('closed')
            });
            elements = this.document.querySelectorAll('.moreinfo-expanded')
            elements.forEach(element => {
                element.classList.remove('show')
                element.classList.add('closed')
            });
        },


        //storytelling detail
        showMoreInfo (index, index2) {
            this.currentMoreInfo = index+'-'+index2
            this.closeAllMoreInfos()

            let element = this.document.getElementById('expanded-' + index + '-' + index2)
            element.classList.remove('closed')
            element.classList.add('show')

            element = this.document.getElementById('moreInfo-' + index + '-' + index2)
            element.classList.remove('closed')
            element.classList.add('show')
        },
        hideMoreInfo () {
            this.currentMoreInfo = false
            this.closeAllMoreInfos()
        },
        stripEmptyTags(html) {
            return html
                .replace(/<p>\s*<\/p>/g, '')  // Remove empty <p> tags
                .replace(/<br\s*\/?>/g, '');  // Remove <br> tags
        },

        openFilmPopup(slug) {
            if (this.popupOpen === false) {
                const project = this.jsonDetail.content[0].projects.find(p => p.slug === slug)
                this.$store.commit('openPopup', project)
            }
        },

    }
}
</script>
